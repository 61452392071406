import React from "react"

import Layout from "../components/layout"
import Menu from '../components/menu';
import SEO from "../components/seo"

const MenuPage = () => {
   
    return (
        <Layout>
            <SEO title="Menu" />
            <Menu />
        </Layout>
    )
}


export default MenuPage;
